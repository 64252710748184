import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Background from "../components/background"

export default () => {
  return (
    <Layout>
      <SEO title="Contact Us" description="Contact the ASPIRE team today!" />
      <Background
        image="background.jpg"
        className="landing bg-dark"
        mode="scroll"
      >
        <h1>CONTACT US</h1>
      </Background>

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col col-md-4">
              <h2>WICHITA</h2>
              <p>Wichita, KS - 67218</p>
              <h2>MEDIA INQUIRIES</h2>
              <p>316-708-2765</p>
              <p>
                <a href="mailto:thomas@aspiretheempire.com">
                  thomas@aspiretheempire.com
                </a>
              </p>
              <hr />
              <p>All other questions, please use the form to the right</p>
            </div>
            <div className="col col-md-8">
              <p>Please include this information to book when emailing us.</p>
              <p>
                <b>
                  Artist or Project Name: <br /> Song/Audio Requirements:
                  <br />
                  Location desired to shoot:
                  <br />
                  Idea or concept for the video:
                  <br />
                  Requested date or time for the shoot:
                </b>
              </p>
              <p>ALL INQUIRIES TO: <a href="mailto:wm.uneak@gmail.com">wm.uneak@gmail.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
